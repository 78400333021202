import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Typography,
  Stack,
  useMediaQuery,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
  Skeleton,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
  Box,
  circularProgressClasses,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Iconify,
  Label,
  MuiDialog,
  MuiDrawer,
  NoData,
  TaskCard,
} from "src/components";
import { capitalCase } from "change-case";
import { get_zone_date, handle_localSearch } from "src/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useAppContext } from "src/hooks";
import { useTransition } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import DatePicker from "react-datepicker";
import CountdownTimer from "src/components/CountdownTimer";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const icon_color = "#637381";
// ---------------------------------------------------------
const TaskSections = ({
  handleLoadMoreTasks,
  projectDetail,
  moreTasksLoading,
  workspaceId,
  section,
  onOpenTaskDetail,
  onOpenComments,
  onExpand,
  onChangePriority,
  onChangeStatus,
  onMovetoWorkspace,
  onChangeDate,
  onSetSelectedTask,
  projectTeam,
  onMakeSectionDefault,
  onChangeMembers,
  onDelTask,
  onDelSection,
  onEditSection,
  onMoveSection,
  onMoveTask,
  projectSections,
  onDragEnd,
  onDragStart,
  // role,
  allowDrag,
  projectTags,
  setProjectTags,
  onChangeTags,
  sections,
  getFilteredTasksBySection,
  setSelectedSection,
  handleUpdateSection,
}) => {
  const navigate = useNavigate();
  const { dispatch_get_user_profile } = useAppContext();
  const userProfile = dispatch_get_user_profile();
  const { role } = projectDetail;
  const { enqueueSnackbar } = useSnackbar();
  const [showTasks, setShowTasks] = useState(false);
  const [isPending, startTransition] = useTransition();
  const matches_break_point = useMediaQuery("(max-width:600px)");
  const [menuOpen, setMenuOpen] = useState(false);
  const { section_title, task } = section;
  const { dispatch_get_search_value } = useAppContext();
  const params = useParams();
  const menu_item_icon_size = { height: "1rem", width: "1rem" };
  const [openSprintTime, setOpenSprintTime] = useState(false);
  const [feedback, setFeedback] = useState(
    section?.feed_back?.length > 0 ? section.feed_back[0]?.feed_back : ""
  );
  const [completeDate, setCompleteDate] = useState(section?.complete_date_time);

  const [sprintTime, setSprintTime] = useState(
    task.end_date_time ? task.end_date_time : new Date(moment("18:00", "HH:mm"))
  );
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [completeDateDialogOpen, setCompleteDateDialogOpen] = useState(false);
  const handleChange = (section) => {
    //solution working for performance!
    // if (section.expanded === false) {
    //   startTransition(() => {
    //     setShowTasks(true);
    //   });
    // }
    // if (section.expanded === true) {
    //   setShowTasks(false);
    // }
    onExpand(section);
  };
  const getFilteredTasks = () => {
    let search = dispatch_get_search_value();
    return handle_localSearch(search, task);
  };
  const getProjectTeam = () => {
    if (projectTeam) {
      return projectTeam;
    } else {
      return [];
    }
  };
  const openFeedbackDialog = () => {
    setFeedbackDialogOpen(true);
  };
  const closeFeedbackDialog = () => {
    setFeedbackDialogOpen(false);
    section?.feed_back?.length > 0 ? section.feed_back[0]?.feed_back : "";
  };
  const openCompleteDateDialog = () => {
    setCompleteDateDialogOpen(true);
  };
  const closeCompleteDateDialog = () => {
    setCompleteDateDialogOpen(false);
    setCompleteDate(section?.complete_date_time);
  };
  const getProjectSections = () => {
    if (projectSections) {
      return projectSections.filter(
        (item) => String(item._id) !== String(section._id)
      );
    } else {
      return [];
    }
  };
  const handleOpenMenu = (e) => {
    console.log(section, "section");
    setMenuOpen(e.currentTarget);
  };
  const handleCloseMenu = (e) => {
    setMenuOpen(null);
  };
  const getVariant = (task) => {
    if (role === "admin") {
      return "default";
    }
    if (role === "manager") {
      return "default";
    }
    if (task.creator_profile.user_id === userProfile.user_id) {
      return "default";
    }
    if (task.user_task_status === true) {
      return "default";
    }
    return "disabled";
  };
  const handleAction = (action, data) => {
    console.log(action, data, "action + data");
    if (action === "move_to_workspace") {
      onMovetoWorkspace(data, workspaceId);
    }
    if (action === "change_status") {
      onChangeStatus(data, workspaceId);
    }
    if (action === "change_priority") {
      onChangePriority(data, workspaceId);
    }
    if (action === "change_date") {
      onChangeDate(data, workspaceId);
    }
    if (action === "change_members") {
      onChangeMembers(data, workspaceId);
    }
    if (action === "del_task") {
      onDelTask(data, workspaceId);
    }
    if (action === "move_task") {
      onMoveTask(data, workspaceId);
    }
    if (action === "drag_end") {
      onDragEnd(data, workspaceId);
    }
    if (action === "drag_start") {
      onDragStart(data, workspaceId);
    }
    if (action === "copy_task_link") {
      handleCopyTaskLink(data);
    }
  };
  const unsecuredCopyToClipboard = (text) => {
    /**
     * Copies the text passed as param to the system clipboard
     * Check if using HTTPS and navigator.clipboard is available
     * Then uses standard clipboard API, otherwise uses fallback
     */
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      enqueueSnackbar("Task link copied to clipboard", {
        variant: "success",
      });
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
      enqueueSnackbar("Failed to copy task link", {
        variant: "error",
      });
    }
    document.body.removeChild(textArea);
  };

  const handleCopyTaskLink = async (task) => {
    const { section_id, _id } = task;
    let project_id = params.id;
    console.log(task, "to-copy");
    let newLink = "";
    newLink = `${window.location.origin}/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`;
    console.log(newLink);
    // unsecuredCopyToClipboard(newLink);
    let has_error = "";
    try {
      await window.navigator.clipboard.writeText(newLink);
    } catch (error) {
      if (error) {
        if (error.message) {
          has_error = error.message;
        }
      }
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    if (has_error === "") {
      enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
      return;
    }
    enqueueSnackbar("Could not copy task link", { variant: "error" });
  };
  const onDel = (type) => {
    onDelSection(section, type);
    handleCloseMenu();
  };
  const onEdit = () => {
    handleCloseMenu();
    onEditSection(section);
  };
  const handleSaveFeedback = async () => {
    let req = {
      section_title: section.section_title,
      list_type: 0,
      start_date_time: section.start_date_time,
      end_date_time: section.end_date_time,
      complete_date_time: section.complete_date_time,
      project_id: section.project.id,
      feed_back: [
        {
          user: {
            first_name: dispatch_get_user_profile().first_name,
            last_name: dispatch_get_user_profile().last_name,
            email: dispatch_get_user_profile().email,
            image: dispatch_get_user_profile().image,
            user_id: dispatch_get_user_profile().user_id,
          },
          feed_back: feedback,
        },
      ],
      sprint_status: section.sprint_status,
    };
    await handleUpdateSection(req, section._id);
    closeFeedbackDialog();
  };
  const handleSaveCompleteDate = async () => {
    if (!completeDate) {
      enqueueSnackbar("Please select sprint complete date", {
        variant: "error",
      });
      return;
    }
    let req = {
      section_title: section.section_title,
      list_type: 0,
      start_date_time: section.start_date_time,
      end_date_time: section.end_date_time,
      complete_date_time: completeDate,
      project_id: section.project.id,
      feed_back: [
        {
          user: {
            first_name: dispatch_get_user_profile().first_name,
            last_name: dispatch_get_user_profile().last_name,
            email: dispatch_get_user_profile().email,
            image: dispatch_get_user_profile().image,
            user_id: dispatch_get_user_profile().user_id,
          },
          feed_back: feedback,
        },
      ],
      sprint_status: completeDate
        ? moment(completeDate).isSame(moment(section.end_date_time), "day") ||
          moment(completeDate).isBefore(moment(section.end_date_time), "day")
          ? "on_time"
          : "late"
        : "",
    };
    await handleUpdateSection(req, section._id);
    closeCompleteDateDialog();
  };
  const onMove = (type) => {
    handleCloseMenu();
    const data = { source_section: section, type: type };
    onMoveSection(data);
    //
  };
  const onDefault = () => {
    handleCloseMenu();
    onMakeSectionDefault(section);
    //
  };
  const getListStyle = (isDraggingOver) => {
    if (isDraggingOver) {
      return {
        backgroundColor: "#68ffa7",
      };
    } else {
      return {};
    }
    //
  };
  const getItemStyle = (isDraggingOver, draggablePropsStyle) => {
    if (isDraggingOver) {
      return {
        opacity: "40%",
        backgroundColor: "#68ffa7",
        ...draggablePropsStyle,
      };
    } else {
      return { ...draggablePropsStyle };
    }
    //
  };
  const handleDragEnd = (result) => {
    const data = { section: section, dragResult: result };
    const { draggableId, destination } = result;
    if (!draggableId) {
      console.log("no draggable id drag-end");
      return;
    }
    if (!destination) {
      console.log("no destination drag-end");
      return;
    }
    handleAction("drag_end", data);
    //
  };
  const handleDragStart = (result, task) => {
    const data = { section: section, dragResult: result };
    const { draggableId, destination } = result;
    if (!draggableId) {
      console.log("no draggable id drag-start");
      return;
    }
    if (!destination) {
      console.log("no destination drag-start");
      return;
    }
    handleAction("drag_start", data);
    //
  };
  const handleExpand = () => {
    if (section.expanded === false) {
      setShowTasks(section.expanded);
    }
    if (section.expanded === true) {
      startTransition(() => {
        setShowTasks(section.expanded);
      });
    }
  };
  const handleScroll = () => {
    const elm = document.getElementById(section._id);
    const taskElm = document.getElementById(section?.scroll_to_task);
    const buttonElm = document.getElementById("more-tasks-button");
    if (elm) {
      if (section.scroll_to_view === true && taskElm) {
        elm.scrollIntoView({ block: "center", behavior: "auto" });
      } else if (section.scroll_to_view === true && !taskElm) {
        setTimeout(() => {
          buttonElm.scrollIntoView({ block: "center", behavior: "auto" });
          buttonElm.classList.add("highlight-task");
        }, 500);
        setTimeout(() => {
          buttonElm.classList.remove("highlight-task");
        }, 3500);
      }
    }
  };
  // useEffect(() => {
  //   handleScroll();
  // }, []);
  // ---------------------------------------------------------

  return (
    <Stack
      className="task-section-parent"
      id={section._id}
      direction="row"
      alignItems="center"
      width={1}
    >
      <Grid container width={1}>
        <Grid item xs={12}>
          <Accordion
            disableGutters
            square={false}
            expanded={section.expanded}
            onChange={(e) => handleChange(section)}
            sx={{ width: "100%" }}
          >
            <AccordionSummary
              style={{ width: "100%", paddingRight: "0px" }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography
                sx={{ color: (theme) => theme.palette.primary.main }}
                fontWeight="bold"
              >
                {section_title}
              </Typography>
              {section.start_date_time &&
                section.end_date_time &&
                !section.complete_date_time && (
                  <Tooltip title="Sprint Date">
                    <Typography
                      variant="subtitle"
                      sx={{
                        ml: 2,
                        fontSize: "12px",
                        color: (theme) => theme.palette.grey[600],
                        mt: "3px",
                      }}
                    >
                      {moment(section.start_date_time).format("DD-MMM-YYYY")} -{" "}
                      {moment(section.end_date_time).format("DD-MMM-YYYY")}
                    </Typography>
                  </Tooltip>
                )}
              {section.complete_date_time && (
                <Typography
                  variant="subtitle"
                  sx={{
                    ml: 2,
                    fontSize: "12px",
                    color: (theme) => theme.palette.grey[600],
                    mt: "3px",
                  }}
                >
                  Completed!
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <AnimatePresence layout initial={false}>
                          {getFilteredTasks().map((task, index) => {
                            return (
                              <motion.div
                                key={task._id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.2 }}
                                exit={{ opacity: 0 }}
                                layout
                              >
                                <Draggable
                                  isDragDisabled={!allowDrag}
                                  key={task._id}
                                  draggableId={task._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Stack
                                      {...provided.draggableProps}
                                      // {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      width={1}
                                      sx={{
                                        position: "relative",
                                        mb: 2,
                                      }}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <TaskCard
                                        task={task}
                                        key={index}
                                        onSetSelectedTask={(task) =>
                                          onSetSelectedTask({
                                            ...task,
                                            sectionData: section,
                                          })
                                        }
                                        variant={getVariant(task)}
                                        projectTeam={getProjectTeam()}
                                        projectSections={getProjectSections()}
                                        onOpenDetail={(task) =>
                                          onOpenTaskDetail(
                                            task,
                                            workspaceId,
                                            section
                                          )
                                        }
                                        onOpenComments={(task) =>
                                          onOpenComments(task, workspaceId)
                                        }
                                        onTaskAction={(action, data) =>
                                          handleAction(action, data)
                                        }
                                        taskSection={section}
                                        isDraggingOver={snapshot.isDragging}
                                        currentUserRole={role}
                                        projectTags={projectTags}
                                        setProjectTags={setProjectTags}
                                        onChangeTags={onChangeTags}
                                        sections={sections}
                                        getFilteredTasksBySection={
                                          getFilteredTasksBySection
                                        }
                                      />
                                      <div className="drag-btn">
                                        {allowDrag === true ? (
                                          <IconButton
                                            {...provided.dragHandleProps}
                                            sx={{ p: "3px" }}
                                          >
                                            <Iconify
                                              sx={{
                                                color: icon_color,
                                              }}
                                              icon="fluent:re-order-dots-vertical-24-filled"
                                            />
                                          </IconButton>
                                        ) : (
                                          <Tooltip title="Not allowed">
                                            <span>
                                              <IconButton
                                                {...provided.dragHandleProps}
                                                sx={{ p: "3px" }}
                                                disabled={true}
                                              >
                                                <Iconify
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette.text
                                                        .disabled,
                                                  }}
                                                  icon="fluent:re-order-dots-vertical-24-filled"
                                                />
                                              </IconButton>
                                            </span>
                                          </Tooltip>
                                        )}
                                      </div>
                                    </Stack>
                                  )}
                                </Draggable>
                              </motion.div>
                            );
                          })}
                          {provided.placeholder}
                        </AnimatePresence>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {section.task.length !== 0 &&
                  section.task_count > section.task.length && (
                    <LoadingButton
                      id="more-tasks-button"
                      sx={{ width: "20%", margin: "auto !important" }}
                      onClick={() =>
                        handleLoadMoreTasks(
                          section.load_more_tasks,
                          section._id
                        )
                      }
                      loading={moreTasksLoading}
                      loadingPosition="end"
                      endIcon={<ExpandMoreIcon />}
                      variant="outlined"
                    >
                      More Tasks
                    </LoadingButton>
                  )}
                <NoData
                  dataLength={getFilteredTasks().length}
                  loading={task.length == 0 && section.task_count > 0}
                  taskSection={true}
                  title="No Tasks Found"
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Stack
        direction="row-reverse"
        alignItems="center"
        spacing={0.4}
        sx={{ position: "absolute", top: "2px", right: "22px" }}
      >
        <IconButton
          disableRipple
          sx={{ p: 0, "&:hover": { bgcolor: "transparent" } }}
          onClick={handleOpenMenu}
        >
          <Iconify icon="fluent:more-vertical-24-filled" />
        </IconButton>
        <Tooltip title="Sprint Progress">
          <Box sx={{ position: "relative" }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) =>
                  theme.palette.grey[
                    theme.palette.mode === "light" ? 200 : 800
                  ],
              }}
              size={40}
              thickness={4}
              // {...props}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              disableShrink
              sx={{
                // color: (theme) =>
                // theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={40}
              thickness={4}
              value={
                section.task_count > 0
                  ? (section.completed_task_count / section.task_count) *
                      100 !==
                    NaN
                    ? (section.completed_task_count / section.task_count) * 100
                    : 0
                  : 0
              }
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                sx={{ mb: "6px" }}
                color="text.secondary"
              >
                {`${Math.round(
                  section.task_count > 0
                    ? ((section.completed_task_count ?? 0) /
                        section.task_count) *
                        100 !==
                      NaN
                      ? (section.completed_task_count / section.task_count) *
                        100
                      : 0
                    : 0
                )}%`}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
        <Label
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
            color: "white",
          }}
          variant="ghost"
          color="success"
        >
          {section.task_count}
        </Label>
        {section.is_default === true && (
          <div style={{ marginRight: "7px" }}>
            <Label
              sx={{
                bgcolor: (theme) => theme.palette.primary.main,
                color: "white",
              }}
              variant="ghost"
              color="success"
            >
              Default
            </Label>
          </div>
        )}
        {/* {!section.end_date_time && (
          <Label
            color="error"
            variant="ghost"
            sx={{ cursor: "pointer" }}
            onClick={onEdit}
          >
            Sprint Date time!
          </Label>
        )} */}
        {/* {(!section.end_date_time || openSprintTime) &&
          (role == "admin" || role == "manager") && (
            <DatePicker
              open={openSprintTime}
              onClickOutside={handleCloseSprintTime}
              portalId="date-picker-root-portal"
              selected={sprintTime ? new Date(sprintTime) : ""}
              value={sprintTime ? new Date(sprintTime) : ""}
              onChange={(date) => setSprintTime(date.toISOString())}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              customInput={
                <div>
                  {!section.end_date_time && (
                    <Label
                      color="error"
                      variant="ghost"
                      sx={{ cursor: "pointer" }}
                      onClick={handleOpenSprinttime}
                    >
                      Sprint Date time!
                    </Label>
                  )}
                </div>
              }
            />
          )} */}
        {/* {section.end_date_time && (
          <Tooltip title="Sprint time">
            <div>
              <CountdownTimer targetDate={new Date(section.end_date_time)} />
            </div>
          </Tooltip>
        )} */}
        {section.start_date_time &&
          section.end_date_time &&
          section.task_count === section.completed_task_count &&
          section.task_count !== 0 && (
            <>
              {" "}
              {section.complete_date_time && (
                <Tooltip title="Sprint Note">
                  <IconButton onClick={openFeedbackDialog}>
                    <Iconify icon="material-symbols:feedback-outline-rounded" />
                  </IconButton>
                </Tooltip>
              )}
              {!section.complete_date_time &&
                role != "client" &&
                role != "client_team" && (
                  <Tooltip title="Sprint Complete Date">
                    <IconButton onClick={openCompleteDateDialog}>
                      <Iconify icon="lucide:calendar-check-2" />
                    </IconButton>
                  </Tooltip>
                )}
            </>
          )}
      </Stack>

      <Menu
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          p: 0.5,
          mt: 1,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.5,
            mx: 0.5,
          },
        }}
      >
        {!section.is_default && (
          <MenuItem onClick={onDefault}>
            Make Default
            <span style={{ marginLeft: "auto" }}>
              <Iconify
                sx={{ ml: "8px", ...menu_item_icon_size }}
                icon="ic:round-settings"
              />
            </span>
          </MenuItem>
        )}
        <MenuItem onClick={onEdit}>
          Edit
          <span style={{ marginLeft: "auto" }}>
            <Iconify
              sx={{ ml: "8px", ...menu_item_icon_size }}
              icon="ic:baseline-mode-edit"
            />
          </span>
        </MenuItem>
        {/* {(role == "admin" || role == "manager") && (
          <MenuItem onClick={handleOpenSprinttime}>
            Sprint Time
            <span style={{ marginLeft: "auto" }}>
              <Iconify
                sx={{ ml: "8px", ...menu_item_icon_size }}
                icon="zondicons:timer"
              />
            </span>
          </MenuItem>
        )} */}
        {section.is_default === false && (
          <MenuItem onClick={() => onDel("without_tasks")}>
            Delete Without Tasks
            <span style={{ marginLeft: "auto" }}>
              <Iconify
                sx={{ ml: "8px", ...menu_item_icon_size }}
                icon="dashicons:trash"
              />
            </span>
          </MenuItem>
        )}
        {section.is_default === false && (
          <MenuItem onClick={() => onDel("with_tasks")}>
            Delete With Tasks
            <span style={{ marginLeft: "auto" }}>
              <Iconify
                sx={{ ml: "8px", ...menu_item_icon_size }}
                icon="dashicons:trash"
              />
            </span>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => onMove("up")}
          disabled={section.move_up === false}
        >
          Move Up
          <span style={{ marginLeft: "auto" }}>
            <Iconify
              sx={{ ml: "8px", ...menu_item_icon_size }}
              icon="akar-icons:arrow-up"
            />
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => onMove("down")}
          disabled={section.move_down === false}
        >
          Move Down
          <span style={{ marginLeft: "auto" }}>
            <Iconify
              sx={{ ml: "8px", ...menu_item_icon_size }}
              icon="akar-icons:arrow-down"
            />
          </span>
        </MenuItem>
      </Menu>
      <Dialog
        open={feedbackDialogOpen}
        onClose={closeFeedbackDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Sprint Note</DialogTitle>
        <DialogContent>
          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography fontWeight={500}>Sprint Start Date</Typography>
              <Typography>
                {section.start_date_time
                  ? moment(section.start_date_time).format("DD-MMM-YYYY")
                  : "Not Added!"}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontWeight={500}>Sprint End Date</Typography>
              <Typography>
                {section.end_date_time
                  ? moment(section.end_date_time).format("DD-MMM-YYYY")
                  : "Not Added!"}
              </Typography>
            </Stack>
            <Stack>
              <Typography fontWeight={500}>Sprint Complete Date</Typography>
              <Typography>
                {section.complete_date_time
                  ? moment(section.complete_date_time).format("DD-MMM-YYYY")
                  : "Not Added!"}
              </Typography>
            </Stack>
          </Stack>
          {(role == "admin" || role == "manager") && (
            <FormControl fullWidth>
              <TextField
                multiline
                label="Sprint Note"
                sx={{ mt: 2 }}
                rows={3}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </FormControl>
          )}
          {role != "admin" && role != "manager" && (
            <Typography sx={{ py: 3 }} fontWeight={700}>
              {feedback ? feedback : "No Sprint Note added yet!"}
            </Typography>
          )}
        </DialogContent>
        {(role == "admin" || role == "manager") && (
          <DialogActions>
            <Button onClick={closeFeedbackDialog}>Cancel</Button>
            <Button onClick={handleSaveFeedback}>Save</Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={completeDateDialogOpen}
        onClose={closeCompleteDateDialog}
        maxWidth="sm"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle>Sprint Complete Date</DialogTitle>
        <DialogContent>
          <Stack
            display="flex"
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack spacing={1} direction="row">
              <Typography fontWeight={600}>Sprint Start Date:</Typography>
              <Typography>
                {section.start_date_time
                  ? moment(section.start_date_time).format("DD-MMM-YYYY")
                  : "Not Added!"}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row">
              <Typography fontWeight={600}>Sprint End Date:</Typography>
              <Typography>
                {section.end_date_time
                  ? moment(section.end_date_time).format("DD-MMM-YYYY")
                  : "Not Added!"}
              </Typography>
            </Stack>
          </Stack>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                PopperProps={{ placement: "auto" }}
                label="Sprint Complete Date"
                inputFormat="DD-MMM-YYYY"
                minDate={
                  section.start_date_time
                    ? moment(section.start_date_time)
                    : moment(section.createdAt)
                }
                value={completeDate ? moment(completeDate) : null}
                onChange={(date) =>
                  setCompleteDate(date ? moment(date).toISOString() : "")
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    name="endDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MMM-YYYY",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCompleteDateDialog}>Cancel</Button>
          <Button onClick={handleSaveCompleteDate}>Save</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
const TaskSection = memo(TaskSections);
export default TaskSection;
